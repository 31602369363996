




























































































import { ref, watch, defineComponent, computed } from '@nuxtjs/composition-api';
import {
  SfIcon, SfButton,
} from '@storefront-ui/vue';
import SfPrice from '../../molecules/Product/SfPrice.vue';
import JetProductCardImage from '../../atoms/JetImage/JetProductCardImage.vue';

export default defineComponent({
  name: 'SfProductCard',
  components: {
    SfPrice,
    SfIcon,
    JetProductCardImage,
    SfButton,
  },
  props: {
    keyp: {
      type: [String, Number],
      default: '',
    },
    product: {
      type: Object
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart',
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      default: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const qty = ref(1);
    const isAddingToCart = ref(false);
    const flags = computed(() => {
      const baseFlags = props.product?.flagtext
        ? [{
          text: props.product.flagtext,
          position: props.product.flagposition,
          color: props.product.flagcolor,
        }]
        : [];
      if (baseFlags.length) {
        baseFlags.push({
          text: 'PROMOÇÃO',
          position: 'inferior',
          color: props.product.flagcolor,
        });
      }
      return baseFlags;
    });

    watch(qty, (newQty) => {
      emit('update:qty', newQty);
    });

    const ariaLabel = () => (props.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist');

    const showAddedToCartBadge = () => !isAddingToCart.value && props.isAddedToCart;

    const currentWishlistIcon = () => (props.isInWishlist ? props.isInWishlistIcon : props.wishlistIcon);

    const wishlistIconClasses = () => {
      const defaultClass = 'sf-button--pure sf-product-card__wishlist-icon';
      return `${defaultClass} ${props.isInWishlist ? 'on-wishlist' : ''}`;
    };

    const toggleIsInWishlist = () => {
      emit('click:wishlist', !props.isInWishlist);
    };

    const addToCart = () => {
      emit('click:add-to-cart', { product: props.product, quantity: qty.value });
      setTimeout(() => {
        isAddingToCart.value = false;
      }, 1000);
    };

    const increaseQty = () => {
      qty.value++;
    };

    const decreaseQty = () => {
      if (qty.value > 1) {
        qty.value--;
      }
    };

    const validateQty = () => {
      if (qty.value < 1) {
        qty.value = 1;
      }
    };

    return {
      qty,
      addToCart,
      increaseQty,
      decreaseQty,
      validateQty,
      wishlistIconClasses,
      ariaLabel,
      showAddedToCartBadge,
      currentWishlistIcon,
      toggleIsInWishlist,
      isAddingToCart,
      flags,
    };
  },
});
