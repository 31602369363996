



































import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SfPrice',
  props: {
    regular: {
      type: [String, Number],
      default: null,
    },
    special: {
      type: [String, Number],
      default: null,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    discount: {
      type: [String, Number],
      default: 0,
    },
    quantity: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props) {
    const value = computed(() => props.special || props.regular);
    const valueWithDiscount = computed(() => {
      let valueToCalc: number = Number.parseFloat(value.value.toString());
      if (typeof value.value === 'string') {
        valueToCalc = Number.parseFloat(value.value.replace(/[^\d.-]+/g, ''));
      }
      return (valueToCalc * (1 - Number.parseFloat(props.discount as string) / 100)) / 100;
    });
    const allMessages = computed(() => {
      const messages = [...props.messages];
      if (props.discount) {
        messages.push(`${props.discount}% OFF pagando no Pix por R$ ${valueWithDiscount.value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`);
      }
      return messages;
    });

    return {
      valueWithDiscount,
      allMessages,
    };
  },
});
