












import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { SfCarouselItem } from '@storefront-ui/vue';
import JetBanner from '~/components/JetUi/organisms/JetBanner/JetBanner.vue';

export default defineComponent({
  name: "BannerCarousel",
  components: {
    JetBanner,
    SfCarouselItem,
  },
  props: {
    banners: {
      type: Array,
      default: () => [
        {
          image: "",
          imageMobile: "",
          link: "/",
        },
      ],
    },
    settings: {
      type: Object,
      default: () => ({
        type: "carousel",
        perView: 1,
        autoplay: 5000,
        hoverpause: true,
        gap: 0,
      }),
    },
    height: {
      type: String,
      default: "421px",
    },
  },
  setup(props) {
    const isMobile = ref(false);
    const isLoaded = ref(false);
    const isDragging = ref(false);
    const startX = ref(0);
    const startY = ref(0);

    onMounted(() => {
      const mediaQuery = window.matchMedia("(max-width: 1024px)");
      const updateMedia = (e) => {
        isMobile.value = e.matches;
        if (e.matches) {
          document.documentElement.style.setProperty(
            "--hero-item-height",
            props.height
          );
        } else {
          document.documentElement.style.removeProperty("--hero-item-height");
        }
      };

      updateMedia(mediaQuery);
      mediaQuery.addEventListener("change", updateMedia);
      isLoaded.value = true;
    });

    const startDrag = (event) => {
      isDragging.value = false;
      startX.value = event.type === 'mousedown' ? event.clientX : event.touches[0].clientX;
      startY.value = event.type === 'mousedown' ? event.clientY : event.touches[0].clientY;
    };

    const onDrag = (event) => {
      const currentX = event.type === 'mousemove' ? event.clientX : event.touches[0].clientX;
      const currentY = event.type === 'mousemove' ? event.clientY : event.touches[0].clientY;

      if (Math.abs(currentX - startX.value) > 5 || Math.abs(currentY - startY.value) > 5) {
        isDragging.value = true;
      }
    };

    const endDrag = (event, link) => {
      if (!isDragging.value) {
        navigateTo(link);
      }
      isDragging.value = false;
    };

    const navigateTo = (link: string) => {
      if (typeof window !== 'undefined') {
        window.open(link, '_blank');
      }
    };

    return {
      isMobile,
      isLoaded,
      startDrag,
      onDrag,
      endDrag,
    };
  },
});
