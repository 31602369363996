






























































































import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
  computed,
} from '@nuxtjs/composition-api';

import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { CmsPage } from '~/modules/GraphQL/types';
import { useContent } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import ProductShowcase from '~/components/JetUi/organisms/Product/ProductShowcase.vue';
import BannerCarousel from '~/components/JetUi/organisms/Banner/BannerCarousel.vue';
import BenefitsDisplay from '~/components/JetUi/organisms/Benefits/BenefitsDisplay.vue';
import JetImage from '~/components/JetUi/atoms/JetImage/JetImage.vue';
import Bigbrands from '~/components/JetUi/molecules/bigbrands/bigbrands.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import BankSlipModal from '~/modules/checkout/pages/Checkout/JetCheckout/JetCheckoutModals/BankSlipModal.vue';
import BlogCarrousel from '~/server/blog/BlogCarrousel.vue';
declare const require: {
  context: (path: string, useSubdirectories?: boolean, regExp?: RegExp) => {
    keys: () => string[];
    (key: string): string;
  };
};
export default defineComponent({
  name: 'HomePage',
  components: {
    Bigbrands,
    SkeletonLoader,
    LoadWhenVisible,
    ProductShowcase,
    BannerCarousel,
    BenefitsDisplay,
    JetImage,
    BankSlipModal,
    BlogCarrousel
  },
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const loaded = ref(false);
    const banners = ref([]);
    const homeBanners = ref([]);
    const settings = {
      type: 'carousel',
      perView: 1,
      autoplay: 5000,
      hoverpause: true,
      gap: 0,
    };

    const loadCarouselBanners = () => {
      const context = require.context(
        '@/static/banners/carousel/desktop',
        false,
        /\.png$/
      );

      banners.value = context.keys().map((fileName) => {
        const filePath = `/banners/carousel/desktop/${fileName.replace(
          './',
          ''
        )}`;
        let [title, _, expire] = fileName
          .replace('./', '')
          .replace('.png', '')
          .split('_');

        let expireDate = new Date(new Date().getFullYear(), parseInt(expire.split('-')[1]) - 1, parseInt(expire.split('-')[0]));
        let today = new Date();
        if (expireDate < today) {
          return null;
        }

        return {
          title,
          expire,
          image: filePath,
          imageMobile: filePath.replace('desktop', 'mobile'),
          link: '/ofertas.html',
        };
      }).filter((banner) => banner !== null);



      loaded.value = true;
    };
    
    const loadHomeBanners = () => {
      const context = require.context(
        '@/static/banners/home/desktop',
        false,
        /\.png$/
      );
      
      homeBanners.value = context.keys().map((fileName) => {
        const filePath = `/banners/home/desktop/${fileName.replace(
          './',
          ''
        )}`;
        let [title, _, order, expire] = fileName
        .replace('./', '')
        .replace('.png', '')
        .split('_');
        
        let expireDate = new Date(new Date().getFullYear(), parseInt(expire.split('-')[1]) - 1, parseInt(expire.split('-')[0]));
        let today = new Date();
        if (expireDate < today) {
          return null;
        }

        return {
          title,
          expire,
          image: filePath,
          imageMobile: filePath.replace('desktop', 'mobile'),
          link: '/',
          order: parseInt(order),

        };
      }).filter((banner) => banner !== null);
        loaded.value = true;
    };


    const page = ref<CmsPage | null>(null);
   

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      loaded.value = true;
      loadCarouselBanners();
      loadHomeBanners();
    });

    const pageTitle = computed(() => page.value?.title || '');

    return {
      page,
      banners,
      settings,
      loaded,
      pageTitle,
      homeBanners,
    };
  },
  head() {
    const pageTitle = 'Espacial Suprimentos';
    return {
      title: pageTitle.toString(),
    };
  },
});
