
















































































import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { SfButton, SfLoader, SfSection, SfCarousel } from '@storefront-ui/vue';


export default defineComponent({
  name: 'BlogCarrousel',
  components: {
    SfSection,
    SfLoader,
    SfButton,
    SfCarousel,
  },
  props: {
    settings: {
      default: () => ({
        type: 'carousel',
        rewind: true,
        perView: 6,
        autoplay: 9000,
        hoverpause: true,
        gap: 30,
        breakpoints: {
          768: {
            perView: 2,
            gap: 10,
            peek: {
              before: 0,
              after: 50,
            },
          },
          576: {
            perView: 1,
            gap: 8,
            peek: {
              before: 0,
              after: 50,
            },
          },
          480: {
            perView: 1,
            gap: 5,
            peek: {
              before: 0,
              after: 15,
            },
          },
        }
      }),
    },
  },
  setup(props) {
    interface Post {
      title: string;
      date: string;
      content: string;
      slug: string;
      author: string;
      tags: string[];
      categories: string[];
      excerpt: string;
      featured_image: string;
      likes: number;
    }

    const posts = ref<Post[]>([]);
    const error = ref<Error | null>(null);
    const postsLoaded = ref<boolean>(false);
    const isMobile = ref(false);

    const checkIfMobile = () => {
      isMobile.value = window.innerWidth < 768;
    };

    const fetchPosts = async () => {
      try {
        const response = await fetch('/api/posts/');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json() as Post[];
        posts.value = data.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        }).slice(0, 3);;
        await fetchLikesForAllPosts();
      } catch (err) {
        error.value = err as Error;
        console.error('Error fetching posts:', err);
      }
    };

    const fetchLikesForAllPosts = async () => {
      const fetchLikesPromises = posts.value.map(post => fetchLikes(post.slug));
      await Promise.all(fetchLikesPromises);
      postsLoaded.value = true;
    };

    const fetchLikes = async (slug: string) => {
      try {
        const response = await fetch(`/api/posts/${slug}/likes`);
        if (response.ok) {
          const data = await response.json();
          const postIndex = posts.value.findIndex(post => post.slug === slug);
          if (postIndex !== -1) {
            posts.value[postIndex] = { ...posts.value[postIndex], likes: data.likes };
          }
        }
      } catch (err) {
        console.error('Error fetching likes:', err);
      }
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const correctedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      return correctedDate.toLocaleDateString(undefined, options);
    };

    onMounted(() => {
      fetchPosts();
      checkIfMobile();
    });

    return {
      posts,
      error,
      postsLoaded,
      formatDate,
      isMobile,
    };
  },
});
