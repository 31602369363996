
























import SvgImage from '~/components/General/SvgImage.vue';

export default {
  components: {
    SvgImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    emphasis: {
      type: String,
      required: false,
      default: '',
    },
    imagePath: {
      type: String,
      required: true,
    },
  },
};
