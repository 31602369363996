












































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import imagePlaceholder from '@storefront-ui/vue';

export default defineComponent({
  name: 'JetProductCardImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    srcsets: {
      type: Array,
      default: () => [],
      validator: (value: any) => value.every((item) => item.resolution && item.src)
        || value.every((item) => item.src && item.width),
    },
    alt: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: null,
      validator: (value: any) => !isNaN(value),
    },
    height: {
      type: [Number, String],
      default: null,
      validator: (value: any) => !isNaN(value),
    },
    placeholder: {
      type: String,
      default: imagePlaceholder,
    },
    loading: {
      type: String,
      default: 'lazy',
      validator: (value: any) => ['', 'lazy', 'eager'].includes(value),
    },
    imageTag: {
      type: String,
      default: 'img',
      validator: (value: any) => ['', 'img', 'nuxt-img', 'nuxt-picture'].includes(value),
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    flags: {
      type: Object,
    },
  },
  setup(props, { attrs }) {
    const loaded = ref(false);

    const onLoad = () => {
      loaded.value = true;
    };

    const formatResolution = (resolution: any) => {
      return resolution.endsWith('x') ? resolution : `${resolution}x`;
    }

    const formatDimension = (size: any) => {
      if (size === null) return;
      return /[%ehmprv-x]$/.test(size) || !Number.parseInt(size, 10)
        ? size
        : `${size}px`;
    }

    const formatBreakpoint = (breakpoint: any) => {
      return breakpoint ? `(max-width: ${breakpoint}px)` : '';
    }

    const prefix = (str: string) => {
      return str ? `${str}, ` : '';
    };

    const srcsetDescriptor = (srcset) => {
      return srcset.width
        ? `${Number.parseInt(srcset.width, 10) || ''}w`
        : formatResolution(srcset.resolution);
    };

    const positionFlags = (flag: { position: string; color: string; text: string }) => {
      if (!flag.position) return;
      return {
        'flag--top': flag.position.toLowerCase().includes('superior'),
        'flag--right': flag.position.toLowerCase().includes('direita'),
        'flag--bottom': flag.position.toLowerCase().includes('inferior'),
        'flag--left': flag.position.toLowerCase().includes('esquerda')
      }
    }

    const sortedSrcsets = computed(() => {
      const arr = [...props.srcsets];

      arr.sort((setA: any, setB: any) => (setA.width && setB.width
        ? Number.parseInt(setA.width) - Number.parseInt(setB.width)
        : Number.parseInt(setA.resolution) - Number.parseInt(setB.resolution)));
      return arr;
    })

    const srcset = computed(() => {
      if (sortedSrcsets.value.length === 0) return null;
      return sortedSrcsets.value.reduce(
        (str: string, set: any) => `${prefix(str)}${set.src} ${srcsetDescriptor(set)}`,
        '',
      );
    })

    const sizes = computed(() => {
      const hasBreakpoints = sortedSrcsets.value.every(
        (set: any) => set.breakpoint && set.width,
      );
      if (!hasBreakpoints) return null;
      return sortedSrcsets.value.reduce(
        (str: string, set: any) => `${prefix(str)}${formatBreakpoint(set.breakpoint)} ${formatDimension(set.width)}`,
        '',
      );
    });

    const classes = computed(() => {
      return loaded.value ? 'sf-image sf-image-loaded' : 'sf-image';
    })

    const imageComponentTag = computed(() => {
      return props.imageTag || 'img';
    });

    const isPlaceholderVisible = computed(() => {
      return loaded.value || (!loaded.value && !props.placeholder);
    });

    const attributes = computed(() => {
      return props.imageTag === 'img' || props.imageTag === ''
        ? {
          ...attrs,
          sizes: sizes.value,
          srcset: srcset.value,
        }
        : {
          ...attrs,
          width: props.width ? props.width : null,
          height: props.height ? props.height : null,
          ...props.nuxtImgConfig,
        };
    });

    const styles = computed(() => {
      const sizeHandler = (size: number | string | null) =>
        size === null ? null : `${size}px`;

      return {
        '--_image-width': sizeHandler(props.width ?? ''),
        '--_image-height': sizeHandler(props.height ?? ''),
      };
    });

    return {
      positionFlags,
      onLoad,
      imageComponentTag,
      attributes,
      classes,
      styles,
      isPlaceholderVisible
    }
  }
});
